<template>
  <el-dialog :title="+$route.query.type === 1 ? '视频' : '图片'"
             v-model="addSelection"
             :before-close="handleClose"
             width="750px">
    <div class="selection-add-main">
      <div class="addForm">
        <div class="searchForm">
          <el-form :inline="true"
                   :model="searchForm"
                   ref="searchRef"
                   class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input v-model="searchForm.user_name"
                        placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item :label="+$route.query.type === 1 ? '视频名称' : '图片名称'">
              <el-input v-model="searchForm.worksName"
                        placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         class="search_button"
                         @click="searchFormClick">查询</el-button>
              <el-button class="search_button"
                         @click="clearForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="worksList">
        <el-checkbox-group v-model="checkList">
          <div class="list-item"
               v-for="(item, index) in worksList"
               :key="index">
            <div class="item-cover"
                 @click.prevent="preview(item)">
              <img :src="item.cover_file_uri"
                   alt=""
                   class="cover"
                   v-if="+$route.query.type === 1">
              <img :src="item.cover_file_uri || item.file_uri"
                   alt=""
                   class="cover"
                   v-else>
              <div class="cover-back">
                <i class="iconfont iconbofang1"
                   v-if="+$route.query.type === 1"></i>
                <div class="cover-title">
                  <div class="title-main">
                    <div class="date">{{$moment(item.created_at*1000).format('YYYY-MM-DD')}}</div>
                    <div class="time">{{item.Newduration}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-info"
                 :class="workListIds.includes(item.id) ? 'item-info-ed' : ''"
                 @click.prevent="preview(item)">
              <div class="info-title">{{item.title}}</div>
              <div class="info-avatar">
                <HsAvatar :url="item.user_face"
                          :type="item.cert_type"
                          :status="item.cert_status"></HsAvatar>
                <span class="name">{{item.nick_name}}</span>
              </div>
            </div>
            <div class="checkout-header">
              <el-checkbox size="medium"
                           :label="item.id"
                           class="check-box"
                           :disabled="workListIds.includes(item.id)">{{''}}</el-checkbox>
            </div>
          </div>
        </el-checkbox-group>
        <div class="none-data"
             v-if="worksList.length<1">暂无数据</div>
      </div>
      <el-pagination layout="prev, pager, next"
                     :total="pagination.total_count"
                     @current-change="handleCurrentChange">
      </el-pagination>
      <div class="footer-btns">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="addClick">确 定</el-button>
      </div>
    </div>
    <Videodetail :worksId="worksId"
                 :showModal="videoShowModal"
                 @closeDetail="closeDetail"></Videodetail>
    <Imgdetail :worksId="worksId"
               :showModal="imgShowModal"
               @closeDetail="closeDetail"></Imgdetail>
  </el-dialog>
</template>

<script>
import { defineComponent, defineAsyncComponent, getCurrentInstance, toRefs, reactive, watch, ref, onMounted } from 'vue'
const Videodetail = defineAsyncComponent(() => import('@/views/worksModule/worksVideos/component/detail.vue'))
const Imgdetail = defineAsyncComponent(() => import('@/views/worksModule/worksImages/component/detail.vue'))
export default defineComponent({
  emits: ['closeAdd', 'addSuccess'],
  components: {
    Videodetail,
    Imgdetail
  },
  props: {
    selectionShow: {
      type: Boolean,
      default: () => false
    },
    listWorksIds: {
      type: Array,
      default: () => []
    }
  },
  setup (props, context) {
    const { proxy } = getCurrentInstance()
    const searchRef = ref(null)
    watch(props, (val) => {
      data.addSelection = val.selectionShow
      data.workListIds = val.listWorksIds
      if (val.selectionShow) {
        worksListReq()
      }
    })
    onMounted(() => {
    })
    const data = reactive({
      addSelection: false,
      workType: proxy.$route.query.type,
      searchForm: {
        user_name: '',
        worksName: ''
      },
      pagination: {
        total_count: 0,
        page: 1,
        limit: 6
      },
      checkList: [],
      worksList: [],
      worksId: 0,
      videoShowModal: false,
      imgShowModal: false,
      workListIds: []
    })

    // 关闭
    const handleClose = () => {
      context.emit('closeAdd')
      data.addSelection = false
      data.checkList = []
    }

    // 重置
    const clearForm = () => {
      data.searchForm = {
        user_name: '',
        worksName: ''
      }
      worksListReq()
    }

    // 添加
    const addClick = () => {
      addRel()
    }

    const handleCurrentChange = (val) => {
      data.pagination.page = val
      worksListReq()
    }

    // 查询
    const searchFormClick = () => {
      worksListReq()
    }

    // 查看详情
    const preview = (val) => {
      data.worksId = val.id
      if (val.type === 1) {
        data.videoShowModal = true
      } else {
        data.imgShowModal = true
      }
    }

    // 关闭详情
    const closeDetail = () => {
      data.videoShowModal = false
      data.imgShowModal = false
    }

    // 请求作品列表
    const worksListReq = async () => {
      try {
        const sd = await proxy.$api.getWorksList({
          type: data.workType,
          status: 1,
          auth_type: 0,
          user_name: data.searchForm.user_name,
          title: data.searchForm.worksName,
          page: data.pagination.page,
          limit: data.pagination.limit
        })
        data.worksList = sd.data.list
        data.pagination = sd.data.pagination
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }

    // 添加作品接口
    const addRel = async () => {
      try {
        await proxy.$api.postAddRel({
          block_id: proxy.$route.query.blockId,
          work_id: data.checkList.join(',')
        })
        context.emit('addSuccess')
        data.addSelection = false
        data.checkList = []
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }
    return {
      ...toRefs(data),
      handleClose,
      searchRef,
      clearForm,
      worksListReq,
      addRel,
      searchFormClick,
      handleCurrentChange,
      preview,
      closeDetail,
      addClick
    }
  }
})
</script>

<style lang="scss" scoped>
.selection-add-main {
  padding: 0px 30px;
}
.addForm {
  .url-btn {
    padding-left: 100px;
  }
}
.worksList {
  height: 300px;
  overflow: auto;
  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  .list-item {
    width: 200px;
    margin-right: 11px;
    margin-bottom: 11px;
    cursor: pointer;
    position: relative;
    .item-cover {
      width: 100%;
      height: 130px;
      position: relative;
      background-color: #000;
      .cover {
        width: 60px;
        height: 100%;
        object-fit: cover;
        display: block;
        margin: 0 auto;
      }
      .cover-back {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        i {
          font-size: 30px;
          color: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .cover-title {
          width: 100%;
          padding: 5px 0px;
          background: rgba($color: #000000, $alpha: 0.5);
          position: absolute;
          bottom: 0;
          .title-main {
            width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            div {
              font-size: 12px;
              color: #fff;
            }
          }
        }
      }
    }
    .item-info {
      padding: 10px;
      background-color: #fff;
      .info-title {
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info-avatar {
        display: flex;
        margin-top: 10px;
        align-items: center;
        span {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
          margin-left: 10px;
        }
      }
    }
    .item-info-ed {
      background-color: #f2f3f9;
      // pointer-events: none;
      cursor: not-allowed;
      .info-title {
        color: #b9bed3;
      }
      .name {
        color: #b9bed3 !important;
      }
    }
    .checkout-header {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    &:hover {
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  .none-data {
    text-align: center;
    color: #666;
    margin-top: 30px;
  }
}
.footer-btns {
  text-align: right;
  padding-bottom: 10px;
  margin-top: 25px;
}
.url-btn::v-deep(.el-button) {
  color: #409eff;
}
</style>
